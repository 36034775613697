@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'sans-serif'
}

a {
    color: #000;
    text-decoration: none;
}

#copyright {
    font-weight:750;
    font-size: 14;
}

#qub-access-card {
    min-height: 500px;
    padding: 30px;
}

#qub-access-card-small {
    /* padding: 10px; */
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

#qub-access-card-media {
    display: flex;
    justify-content: center;
}

#qub-access-card-actions {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

#qub-login-card-paper {
    height: 100%;
    width: 100%;
    border-width: 1;
}

#qub-login-card-container {
    height: 55%;
    width: 450;
    /* background-color: red; */
}

#qub-login-card-content-container {
    height: 100%;
    /* background-color: teal; */
}

#qub-login-card-header-container {
    height: 30%;
}

#qub-login-form-container {
    height: 65%;
    width: 100%;
    /* background-color: teal; */
}

#qub-login-form-container-sm {
    height: 50%;
    /* background-color: teal; */
}

.qub-login-form {
    width: 100%;
}

.qub-login-input {
    width: 90%;
}

#qub-login-title{
    font-weight: 400;
}

form {
    height: 100%;
}

/* #qub-popper-paper {
    margin-top: 0.1em;
} */

#qub-dynamic-menu-root {
    display: flex;
    flex-direction: column;
}

#qub-dynamic-menu-root-item {
    margin: 10px ;
    display: flex;
    align-items: center;
    align-content: baseline;
    justify-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
    vertical-align: middle;
}

#qub-dynamic-menu-item {
    display: flex;
    align-items: center;
    align-content: baseline;
    justify-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
    vertical-align: middle;
}

.icons {
    width: 0.5em;
}

.qub-sidebar {
    /* max-width: 240px; */
    width: 100%;
    height: 100vh;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
.qub-sidebar-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.qub-sidebar-item-content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
}

.qub-sidebar-item-icon {
    margin-right: 6px;
}

.qub-sidebar-item-text {
    width: 100%;
}

.qub-sidebar-item-expand-arrow {
    font-size: 1.2rem !important;
}

.qub-sidebar-item-expand-arrow-expanded {
    /* color: #09bb12; */
    font-weight: bold;
}

.qub-drawer {
    width: 20%;
    flex-shrink: 0;
}

.qub-drawer-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.qub-appBar {
    align-items: center;
}

.qub-toolbar {
    border-bottom: 1px solid #E4E4E4;
    width: 95%;
}

.qub-toolbar-title {
    flex: 1 1;
}

.qub-toolbar-secondary {
    justify-content: space-between;
    overflow-x: auto;
}

.qub-offset {
    min-height: 64px;
}

.qub-centered-div {
    display: flex;
    align-content: center;
    justify-content: center;
}

.qub-dynamic-material-icon {
    margin-right: 0.2em;
}


